import React from 'react'
import './DiscoverGula.css'
import { Col, Container, Row } from 'react-bootstrap'
import CommanButton from '../../CommanComponent/CommanButton/CommanButton'
import ManufacturingCard from '../../CommanComponent/ManufacturingCard/ManufacturingCard'
import ClientCard from './ClientCard/ClientCard'
import { Link } from 'react-router-dom'

const manufacturingCards = [
    { image: "/assests/images/icon/image-1.png", textName: "In-licensing" },
    { image: "/assests/images/icon/image-3.png", textName: "Out-licensing" },
    { image: "/assests/images/icon/image-4.png", textName: "Contract Manufacturing" },
    { image: "/assests/images/icon/image-2.png", textName: "Product Portfolio" },
    { image: "/assests/images/icon/image-5.png", textName: "Trading" },
    { image: "/assests/images/icon/image-6.png", textName: "Sourcing" },
];

const clientCards = [
    { image: "/assests/images/icon/clients.png", textName: "Clients", start: "0", end: "50", counterText: '+' },
    { image: "/assests/images/icon/glucose.png", textName: "Products", start: "0", end: "500", counterText: '+' },
    { image: "/assests/images/icon/export.png", textName: "Export to countries", start: "0", end: "12", counterText: '+' },
];

const DiscoverGula = () => {
    return (
        <>
            <section className='Discove-Gula'>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={7} md={6} className='details-holder'>
                            <div className='heading-holder '>
                                <h3 className='mb-xxl-5 mb-xl-4 mb-lg-2 mb-md-2 mb-sm-2 mb-2'>WHY OPT FOR <br />  GULA PHARMACEUTICAL?</h3>
                                {/* <h2>Discover Gula Pharmaceutical</h2> */}
                                <p>Gula Pharmaceutical is a healthcare company located in Pune India established with a vision to provide best
                                    quality healthcare products to it’s Customers located world wide. <br /> <br />
                                    Our vast range of tablets capsules syrups and injections cover all major health issues of our patients<br /> <br />
                                    Our mission is to serve our customers with best possible medicinal aids and enhance their welbeing in a cost effective way.
                                </p>
                            </div>
                            <div className='view-details'>
                                <Link to="/about-us">
                                    <div className="button-holder">
                                        <CommanButton text={"About Us"} text1={"About Us"} />
                                    </div>
                                </Link>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={5} md={5} className='mx-auto'>
                            <div className='discover-img'>
                                <div className='image-holder'>
                                    <img className="discover-gula" src={process.env.PUBLIC_URL + "/assests/images/home/discover-gula.png"} alt="discover-gula" />
                                </div>
                                <div className="current-hover"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='card-section'>
                        <Col xxl={5} xl={6} lg={6} md={8} className='me-auto'>
                            <Row>

                                <div className='image-trusted'>
                                    <img className="trusted-img" src={process.env.PUBLIC_URL + "/assests/images/home/aboutimage1.png"} alt="trusted-img" />
                                    <div className='second-img'>
                                        <img className="trusted-img2" src={process.env.PUBLIC_URL + "/assests/images/home/aboutimage2.png"} alt="trusted-img2" />
                                    </div>

                                </div>


                                {/* {manufacturingCards.map((card, index) => (
                                    <Col key={index} lg={4} md={6} sm={6}>
                                        <ManufacturingCard
                                            image={process.env.PUBLIC_URL + card.image}
                                            textName={card.textName}
                                        />
                                    </Col>
                                ))} */}
                            </Row>
                        </Col>
                        <Col xxl={4} xl={4} lg={5} md={4} className='mx-auto'>
                            <div className='text-name'>
                                <h3>Your Trusted <br /> Healthcare Partner</h3>
                            </div>
                            {/* {clientCards.map((card, index) => (
                                <ClientCard
                                    key={index}
                                    image={process.env.PUBLIC_URL + card.image}
                                    textName={card.textName}
                                    start={card.start}
                                    end={card.end}
                                    counterText={card.counterText}
                                />
                            ))} */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default DiscoverGula
