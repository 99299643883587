import React from 'react'
import './AboutUsSec.css'
import { Col, Container, Row } from 'react-bootstrap'
import CommanButton from '../../CommanComponent/CommanButton/CommanButton'
import { Link } from 'react-router-dom'
const AboutUsSec = () => {
    return (
        <>
            <section className='About-Us-Sec'>
                <Container>
                    <Row>
                        <Col md={6} className='about-col'>
                            <div className='heading-holder mb-4'>
                                <h2 className='mb-xxl-5 mb-xl-4 mb-lg-2 mb-md-2 mb-sm-2 mb-2'>About Us</h2>
                                <p>Gula Pharmaceutical is a healthcare company located in Pune India established with a vision to
                                    provide best quality healthcare products to it’s Customers located world wide. <br /><br />
                                    Our vast range of tablets capsules syrups and injections cover all major health issues of our patients. <br /><br />
                                    Our mission is to serve our customers with best possible medicinal aids and enhance their welbeing in a cost effective way.
                                </p>
                            </div>
                            <div className='view-details'>
                                <Link to="/contact-us">
                                    <div className="button-holder">
                                        <CommanButton text={"Enquire"} text1={"Enquire"} />
                                    </div>
                                </Link>
                            </div>
                        </Col>
                        <Col md={1} className='about-col'>
                            <div className='orange-circle'></div>
                        </Col>
                        <Col md={5}>
                            <div className='image-holder'>
                                <img className="about-image" src={process.env.PUBLIC_URL + "/assests/images/about/medical-banner.png"} alt="about-image" />
                                <div className='circle-holder'> </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AboutUsSec
