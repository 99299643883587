import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ManufacturingCard from '../../CommanComponent/ManufacturingCard/ManufacturingCard';
import './BelowCardSec.css'
const BelowCardSec = () => {
    const dataDetails = [
        {
            HeadingName: "Pharmaceuticals",
            image: process.env.PUBLIC_URL + "/assests/images/icon/medition.png",

        },
        {
            HeadingName: "Nutraceuticals",
            image: process.env.PUBLIC_URL + "/assests/images/icon/nutrlics.png",

        },
        {
            HeadingName: "Orthopedics",
            image: process.env.PUBLIC_URL + "/assests/images/icon/orthopedics.png",

        },
        {
            HeadingName: "Surgicals",
            image: process.env.PUBLIC_URL + "/assests/images/icon/surgery.png",

        },
        {
            HeadingName: "Medical Devices",
            image: process.env.PUBLIC_URL + "/assests/images/icon/lifeline.png",

        }
    ];
    return (
        <>
            <section className='below-card-sec'>
                <Container>
                    <Row className='justify-content-center'>
                        {dataDetails.map((card, index) => (
                            <Col lg={2} md={3} sm={4}  key={index}>
                                <ManufacturingCard
                                    image={card.image}
                                    textName={card.HeadingName}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default BelowCardSec
