import React from 'react'
import './ManufacturingCard.css'
const ManufacturingCard = ({ image, textName }) => {
    return (
        <>
            <div className='card-manufacturing'>
                <div className='image-icon text-center'>
                    <img src={image} className="icon-img" alt="icon-img" />
                </div>
                <div className='text-holder text-center'>
                    <p>{textName}</p>
                </div>
                <div className='card-overlay'></div>
            </div>
        </>
    )
}

export default ManufacturingCard
