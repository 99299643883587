import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  fas,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fas);
const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <div className="border-line"></div>
            <Col xxl={3} xl={3} lg={3}>
              <Link to={"/home"}>
                <div className="logo-footer">
                  <img
                    className="header-logo"
                    src={
                      process.env.PUBLIC_URL + "/assests/images/logo/logo.png"
                    }
                    alt="Logo"
                  />
                </div>
              </Link>
            </Col>
            <Col xxl={3} xl={3} lg={3}>
              <div className="quick-link">
                <h3>QUICK LINKS</h3>
                <div className="foot-menu title">
                  <Link to="/home"> Home </Link>
                </div>
                <div className="foot-menu title">
                  <Link to="/about-us"> About Us </Link>
                </div>
                <div className="foot-menu title">
                  <Link to="/product"> Products</Link>
                </div>
                <div className="foot-menu title">
                  <Link to="/contact-us"> Contact</Link>
                </div>
                <div className="foot-menu title">
                  <Link to="/privacy-policy"> Privacy Policy</Link>
                </div>
                <div className="foot-menu title">
                  <Link to="/terms-condition">Terms and Conditions</Link>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3}>
              <div className="quick-link">
                <h3>CONTACT US</h3>
                <div className=" d-flex mb-2">
                  <FontAwesomeIcon
                    className="call-icon me-2"
                    icon={faEnvelope}
                  />
                  <p>info@gula-pharmaceutical.com</p>
                </div>
                <div className=" d-flex  ">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="call-icon me-2"
                  />
                  <p>
                    Shop No -1, 2160 Sadashiv Peth, Swamipuram Appartment Pune -
                    411030, Maharashtra 27
                  </p>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3}>
              <div className="quick-link linkss">
                <h3>LINKS</h3>

                <div className="  social-icon">
                  <div className="border-icon mb-2 text-center">
                    <FontAwesomeIcon icon={faFacebookF} className="fb-icon" />
                  </div>
                  <div className="border-icon  mb-2 text-center">
                    <FontAwesomeIcon icon={faInstagram} className="fb-icon" />
                  </div>
                  <div className="border-icon text-center">
                    <FontAwesomeIcon icon={faYoutube} className="fb-icon" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              {/* <div className="text-holder text-center">
                <h5>2nd Floor, Office No 3, M No. 734,Opp High Class Society, Pashan Rd,<br /> near Suryadutta College, Patil Nagar,Bavdhan, Pune, Maharashtra 411021</h5>
              </div> */}
              <div className="border-copyright my-lg-4 my-3"></div>
              <div className="copyright-holder text-center">
                <p>
                  © 2024 GULA Pharmaceutical. All Rights Reserved.
                  <Link to="https://profcymaglobal.com/" target="___blank">
                    Developed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
