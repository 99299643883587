import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './PageNotFound.css'
const PageNotFound = () => {
    return (
        <>
            <section className='product-not-found'>
                <Container>
                    <Row>
                        <div className='not-found-img text-center'>
                            <img className="no-image" src={process.env.PUBLIC_URL + "/assests/images/product/not-found.png"} alt="Logo" />
                        </div>
                        <div className='text-found'>
                            <p>Page Not Found</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PageNotFound
