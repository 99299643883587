import React from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import AboutUsSec from './AboutUsSec/AboutUsSec'
import BelowCardSec from './BelowCardSec/BelowCardSec'
import HonorAward from './HonorAward/HonorAward'
import GlobalReach from '../home/GlobalReach/GlobalReach'
import GetInTouch from '../home/GetInTouch/GetInTouch'

const About = () => {
    return (
        <>
            <AboutBanner />
            <AboutUsSec />
            <BelowCardSec />
            {/* <HonorAward /> */}
            <GlobalReach />
            <GetInTouch />
        </>
    )
}

export default About
