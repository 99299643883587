import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './CommanButton.css'
import { Button } from 'react-bootstrap';
const CommanButton = ({ onClick,
    className,
    margin,
    text,
    border,
    background,
    borderRadius,
    fontSize,
    color,
    fontWeight,
    padding,
    text1,
    showHeart, }) => {
    const buttonStyle = {
        background,
        borderRadius,
        border,
        fontSize,
        fontWeight,
        color,
        padding,
        margin,
        className,
    };
    return (
        <>
            <div class="button">
                <span class="label-up">{text}</span>
                <span class="label-up">{text1}</span>
            </div>
            {/* <Button type="button" className="label-up" onClick={onClick}>
                <span>{text}</span>
                <span>{text1}</span>
            </Button> */}
        </>
    )
}

export default CommanButton
