import React, { useEffect, useRef } from 'react'
import './Welcomepage.css'
const Welcomepage = () => {
    const vidRef = useRef();

    useEffect(() => {
        vidRef.current.play();
    }, []);
    return (
        <>
            <section className='welcome-page'>
                <div className="video-main">
                    {/* <video className='video-bg' autoPlay loop muted>
                        <source src={process.env.PUBLIC_URL + "/assests/images/logo/GULAOpening.mp4"} className='video-bg' type="video/mp4" />
                    </video> */}
                    <video className="video-bg" src={process.env.PUBLIC_URL + "/assests/images/logo/GULAOpening.mp4"} ref={vidRef} muted loop />
                </div>
            </section>
        </>
    )
}

export default Welcomepage
