import "./banner/Banner.css";
import Banner from "./banner/Banner";
import DiscoverGula from "./DiscoverGula/DiscoverGula";
import GlobalReach from "./GlobalReach/GlobalReach";
import GetInTouch from "./GetInTouch/GetInTouch";
import ProductCategory from "./ProductCategory/ProductCategory";
import TheyTrust from "./TheyTrust/TheyTrust";

function Home() {
  return (
    <>
      <Banner />
      <DiscoverGula />
      <GlobalReach />
      {/* <ProductCategory /> */}
      <GetInTouch />
      {/* <TheyTrust /> */}
    </>
  );
}

export default Home;
