import React from 'react'
import "./SuccesfullModal.css";
import { Button, Modal } from "react-bootstrap";
import Successfull_Lottie from "../SuccesfullModal/Successfull_Lottie/Successfull_Lottie";
const SuccesfullModal = (props) => {
    const { message } = props;
    return (
        <>
            <div className="Successfull_Modal_sec">
                <Modal
                    className="Successfull_Modal"
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="lottie-holder">
                            {" "}
                            <Successfull_Lottie />
                        </div>

                        <p>{message}</p> {/* Displaying the 'message' prop */}
                    </Modal.Body>
                </Modal>
            </div>
            
        </>
    )
}

export default SuccesfullModal
