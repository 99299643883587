import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import CommanButton from "../CommanComponent/CommanButton/CommanButton";
library.add(fas);

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  // useEffect(() => {
  //   const path = location.pathname.substring(1);

  //   if (["services", "trading", "contract-manufacturing", "named-patient", "product", "product-enquiry"].includes(path)) {
  //     setActiveLink("services");
  //   } else {
  //     setActiveLink(path || "home");
  //   }
  // }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <>
      <section className="main-header">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/home">
              <img className="headlogo" src={process.env.PUBLIC_URL + "/assests/images/logo/logo.png"} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link
                  as={Link}
                  to="/home"
                  className={`nav-link-with-borders ${activeLink === "home" ? "active" : ""}`}
                  onClick={() => handleNavItemClick("home")}
                >
                  Home
                  <div className="">
                    <div className="white-border"></div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about-us"
                  className={`nav-link-with-borders ${activeLink === "about-us" ? "active" : ""}`}
                  onClick={() => handleNavItemClick("about-us")}
                >
                  About Us
                  <div className="">
                    <div className="white-border"></div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/product"
                  className={`nav-link-with-borders ${activeLink === "product" ? "active" : ""}`}
                  onClick={() => handleNavItemClick("product")}
                >
                  Products
                  <div className="">
                    <div className="white-border"></div>
                  </div>
                </Nav.Link>
                {/* <NavDropdown
                  title={
                    <span className={`nav-link-with-borders ${activeLink === "services" ? "active" : ""}`}>
                      Services
                      <div className="white-border-active"></div>
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/services" onClick={() => handleNavItemClick("services")}>Products</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/trading" onClick={() => handleNavItemClick("trading")}>Trading</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/contract-manufacturing" onClick={() => handleNavItemClick("contract-manufacturing")}>Contract Manufacturing</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/named-patient" onClick={() => handleNavItemClick("named-patient")}>Named Patient Supply</NavDropdown.Item>
                </NavDropdown> */}
                <Nav.Link
                  as={Link}
                  to="/contact-us"
                  className={`nav-link-with-borders ${activeLink === "contact-us" ? "active" : ""}`}
                  onClick={() => handleNavItemClick("contact-us")}
                >
                  Contact
                  <div className="">
                    <div className="white-border"></div>
                  </div>
                </Nav.Link>
                <Link to="/contact-us">
                  <div className="button-holder">
                    <CommanButton text={"Enquire"} text1={"Enquire"} />
                  </div>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </section>
    </>
  );
}

export default Header;
