import React from 'react'
import './GlobalReach.css'
import { Container, Row } from 'react-bootstrap'
const GlobalReach = () => {
    // const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <section className='global-reach'>

                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <h2>Global Reach</h2>
                            <p>Bringing global healthcare access with quality products and partnerships</p>
                        </div>

                        <div className='main-box'>
                            <video className='video-class-grid' autoPlay loop muted>
                                <source src={process.env.PUBLIC_URL + "/assests/images/home/banner/global-reach.mp4"} className='video' type="video/mp4" />
                            </video>
                            <div className='col-md-6'>
                                <div className='autowalaimgdiv '


                                // onMouseEnter={() => setIsHovered(true)}
                                // onMouseLeave={() => setIsHovered(false)}
                                >
                                    <img src={process.env.PUBLIC_URL + ("/assests/images/home/worldmap.png")}
                                        className="mobileotowala"
                                        alt="Autowala"
                                    />
                                    {/* <img src={process.env.PUBLIC_URL + (isHovered ? "/assests/images/home/map1.png" : "/assests/images/home/map2.png")}
                                        className="mobileotowala"
                                        alt="Autowala"
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GlobalReach
