import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './GetInTouch.css'
import CommanButton from '../../CommanComponent/CommanButton/CommanButton'
import { Link } from 'react-router-dom'
const GetInTouch = () => {
    return (
        <>
            <section className='get-in-touch'>
                <Container>
                    <div className='get-touch-box'>
                        <Row>
                            <Col lg={6} md={6}>
                                <div div className='image-box-holder'>
                                    <div className='image-holder'>
                                        <img className="getin-img" src={process.env.PUBLIC_URL + "/assests/images/home/get-image.png"} alt="getin-img" />
                                        <div className='overlay-color'></div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6}>
                            
                                <div className=' main-box'>
                                <div className='heading-name'>
                                <h3>Our Values</h3>
                            </div>
                                <div className='touch-country '>
                                        <div className='circle-box '>
                                            <img className="bg-img" src={process.env.PUBLIC_URL + "/assests/images/icon/bg-icon.png"} alt="bg-img" />
                                            <div className='icon-img'>
                                                <img className="get-icon" src={process.env.PUBLIC_URL + "/assests/images/icon/Integrity.png"} alt="get-icon" />
                                            </div>
                                        </div>
                                        <div className='text-heading'>
                                            <p>Integrity</p>
                                        </div>
                                    </div>
                                    <div className='touch-country '>
                                        <div className='circle-box '>
                                            <img className="bg-img" src={process.env.PUBLIC_URL + "/assests/images/icon/bg-icon.png"} alt="bg-img" />
                                            <div className='icon-img'>
                                                <img className="get-icon" src={process.env.PUBLIC_URL + "/assests/images/icon/Consistency.png"} alt="get-icon" />
                                            </div>
                                        </div>
                                        <div className='text-heading'>
                                            <p>Consistency</p>
                                        </div>
                                    </div>
                                    <div className='touch-country'>
                                        <div className='circle-box '>
                                            <img className="bg-img" src={process.env.PUBLIC_URL + "/assests/images/icon/bg-icon.png"} alt="bg-img" />
                                            <div className='icon-img'>
                                                <img className="get-icon" src={process.env.PUBLIC_URL + "/assests/images/icon/PatientFirst.png"} alt="get-icon" />
                                            </div>
                                        </div>
                                        <div className='text-heading'>
                                            <p>Patient First</p>
                                        </div>
                                    </div>
                                    <div className='touch-country'>
                                        <div className='circle-box '>
                                            <img className="bg-img" src={process.env.PUBLIC_URL + "/assests/images/icon/bg-icon.png"} alt="bg-img" />
                                            <div className='icon-img'>
                                                <img className="get-icon" src={process.env.PUBLIC_URL + "/assests/images/icon/PromptService.png"} alt="get-icon" />
                                            </div>
                                        </div>
                                        <div className='text-heading'>
                                            <p>Prompt Service</p>
                                        </div>
                                    </div>
                                    <div className='touch-country'>
                                        <div className='circle-box '>
                                            <img className="bg-img" src={process.env.PUBLIC_URL + "/assests/images/icon/bg-icon.png"} alt="bg-img" />
                                            <div className='icon-img'>
                                                <img className="get-icon" src={process.env.PUBLIC_URL + "/assests/images/icon/get-2.png"} alt="get-icon" />
                                            </div>
                                        </div>
                                        <div className='text-heading'>
                                            <p>Highest Quality Products</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        {/* <Col md={6}> */}
                        <div className='bg-color'>
                            <div className='whie-boxx'>
                                <div className='text-holder'>
                                    <h2>Stay Health Updated </h2>
                                    <h3>with just a matter of click</h3>
                                </div>
                                <Link to="/Contact-us">
                                    <div className="button-holder">
                                        <CommanButton text={"Get in Touch"} text1={"Get in Touch"} />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {/* </Col> */}
                    </div>
                </Container>
            </section>
        </>
    )
}

export default GetInTouch
