import React from 'react'
import { Container, Row } from 'react-bootstrap'
// import './AboutBanner.css'
import CommanBanner from '../../CommanComponent/CommanBanner/CommanBanner'
const AboutBanner = () => {
    return (
        <>
            <section className='about-banner'>
                <CommanBanner
                    image={process.env.PUBLIC_URL + "/assests/images/about/about-banner.png"}
                    FirstHeading="Delivering Quality <br /> to Enhance Lives!"
                />
            </section>
        </>
    )
}

export default AboutBanner
