import React from 'react'
import { Container, Row } from 'react-bootstrap'
import CommanBanner from '../CommanComponent/CommanBanner/CommanBanner'
import './PrivacyPolicy.css'
import BreadcrumComponent from '../CommanComponent/BreadcrumComponent/BreadcrumComponent'
const PrivacyPolicy = () => {
    const breadcrumbItems = [
        { text: "HOME", link: "/home" },
        { text: "PRIVACY POLICY", link: "", className: "active" },
    ];
    return (
        <>
            <CommanBanner
                image={process.env.PUBLIC_URL + "/assests/images/banner/privacy-banner.png"}
                FirstHeading="Privacy<br> Policy"
            />
            <section className='privacy-policy'>
                <Container>
                    <Row>
                        <div className='box-color'>
                            <div className='my-2'>
                                <BreadcrumComponent items={breadcrumbItems} />
                            </div>
                            <div className='text-holder'>
                                <p>By consenting to the terms on this site (the “Website”), you are entering into the following Terms and Conditions
                                    established with GULA Pharmaceutical PLEASE REVIEW THESE TERMS THOUGHTFULLY. If you disagree, please leave the site
                                    and refrain from further use.</p>
                            </div>
                            <div className='text-holder'>
                                <h2>1. Definitions</h2>
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                            <div className='text-holder'>
                                <h2>2. Privacy</h2>
                                <p>By accessing this Website, you are accepting our Privacy Policy, the details of which can be found at http://gulapharmaceutical.com/privacy-policy/.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not
                                    only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
                                    1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
                                    like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
                                    five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                                    including versions of Lorem Ipsum.</p>
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PrivacyPolicy
