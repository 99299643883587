import React, { useState } from 'react'
import { Col, Container, Form, Row, Dropdown, Pagination } from 'react-bootstrap'
import BreadcrumComponent from '../../CommanComponent/BreadcrumComponent/BreadcrumComponent';
import './ProductPageCard.css'
import { Link } from 'react-router-dom';
import ProductCard from '../../CommanComponent/ProductCard/ProductCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
const ProductPageCard = () => {
    const breadcrumbItems = [
        { text: "HOME", link: "/home" },
        { text: "PRODUCTS", link: "", className: "active" },

    ];
    const [selectedSort, setSelectedSort] = useState('Sort by: Default');

    const handleSelect = (sortOption) => {
        setSelectedSort(sortOption);
    };
    const productData = [
        {

            HeadingName: "D VITAS FORT",
            unitText: "Cholecalciferol Tablets 10000 IU",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/DVITASFORT.png",

        },
        {

            HeadingName: "D VITAS PREMIUM",
            unitText: "Cholecalciferol Tablets 50000 IU",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/DVITASPREMIUM.png",

        },
        {

            HeadingName: "EMBAGULE",
            unitText: "Empagliflozin Tablets 25 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/Embagule.png",


        },
        {

            HeadingName: "ESOFLUX ",
            unitText: "Esomeprazole Tablets 20 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/ESOFLUX.png",

        },
        {

            HeadingName: "ESOFLUFORT  ",
            unitText: "Esomeprazole Tablets 40 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/ESOFLUXFORT.png",

        },
        {

            HeadingName: "FEBURAT",
            unitText: "Febuxostat Tablets 80 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/FEBURAT.png",

        },
        {

            HeadingName: "GULAXIGA",
            unitText: "Dapagliflozin Tablets 10 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/GULAXIGA.png",

        },

        {

            HeadingName: "KOMBITECH",
            unitText: "Hydrochloride Tablet 2.5/1000 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/KOMBITECH.png",

        },
        {

            HeadingName: "KOMBITECH",
            unitText: "Hydrochloride Tablet 5/1000 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/KOMBITECHPhotoroom.png",

        },
        {

            HeadingName: "PANTOFLUX",
            unitText: "Pantoprazole sodium Tablet USP 40 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/PANTOFLUX.png",

        },
        {

            HeadingName: "ROSOVASAN",
            unitText: "Rosuvastatin Tablet 10 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/ROSOVASAN.png",

        },
        {

            HeadingName: "SITAMET 50/1000",
            unitText: "metformin hydrochloride & Sitagliptin  BP",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/SITAMET.png",

        },
        {

            HeadingName: "TAMSI 0.4 Mg",
            unitText: "Tumsulosin Released Capsule USP ",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/TAMSI.png",

        },
        {

            HeadingName: "TAMSIPROST 0.4/0.5 Mg",
            unitText: "Tumsulosin hydrochloride & Dustasteride",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/TAMSIPROST.png",

        },
        {

            HeadingName: "VILDAMET 50/1000 Mg",
            unitText: "Vildagliptin & Metformin hydrochloride",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/VILDAMET.png",

        },
        {

            HeadingName: "VISCAS",
            unitText: "Solifenacin Tablets BP 5 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/Viscas.png",

        },
        {

            HeadingName: "VISCAS FORT ",
            unitText: "Solifenacin Tablets BP 10 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productset2/Viscasfort.png",

        },
        {

            HeadingName: "URISET 100",
            unitText: "Phenazopyridine Hydrocloride Tablets",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-1.png",

        },
        {

            HeadingName: "ITABAY 90",
            unitText: "Etoricoxib Tablets 90 mg",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-2.png",

        },
        {

            HeadingName: "DOPA Met",
            unitText: "Dapagliflozin + Metformin Hydrochoride",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-3.png",


        },
        {

            HeadingName: "Olmetan Plus ",
            unitText: "Olsartan Medoxomin + Hydrochoride ",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-4.png",

        },
        {

            HeadingName: "DEFACORT  ",
            unitText: "Deflazacort Tablets 6 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-5.png",

        },
        {

            HeadingName: "Olmetan Plus",
            unitText: "Olsartan Medoxomin + Hydrochoride",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-6.png",

        },
        {

            HeadingName: "DEFACORT ",
            unitText: "Deflazacort Tablets 30 Mg",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-7.png",

        },

        {

            HeadingName: "ONDANSET",
            unitText: "ondansetron Injection usp",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-8.png",

        },
        {

            HeadingName: "SECRIL FORT 100",
            unitText: "Sacubitril + Valsartan Tablet",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-9.png",

        },
        {

            HeadingName: "ONDANET 8Mg",
            unitText: "Ondansetron Tablets (Film-Coated)",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-10.png",

        },
        {

            HeadingName: "CLOMIPHENE 50 Mg",
            unitText: "Citrate Tablets usp",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-11.png",

        },
        {

            HeadingName: "UVANTION 100",
            unitText: "Nitrofurantoin Capsule ",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-12.png",

        },
        {

            HeadingName: "ITABAY 60",
            unitText: "Etoricoxib Tablets 60mg ",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-13.png",

        },
        {

            HeadingName: "SACRIL 50",
            unitText: "Sacubitril + Valsartan Tablets",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-14.png",

        },
        {

            HeadingName: "EMPA MET",
            unitText: "Empagliflozin + Metformin Hydrochloride ",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-15.png",

        },
        {

            HeadingName: "LINA MET",
            unitText: "Lingagliptin + Metformin Hydrochloride",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-16.png",

        },
        {

            HeadingName: "PIOMET FORT ",
            unitText: "Pioglitazone + Metformin Hydrochloride",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-17.png",

        },
        {

            HeadingName: "URISET 100",
            unitText: "Phenazopyridine Hydrochloride Tablets",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-18.png",

        },
        {

            HeadingName: "GLACLAVE 1000",
            unitText: "Amoxicillin & Potasium Clavulanate",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-19.png",

        },
        {

            HeadingName: "LEVOSIM 500",
            unitText: "Levofloxacin Tablets",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-20.png",

        },
        {

            HeadingName: "MOXISIM 400",
            unitText: "Moxifloxacin Tablets",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-21.png",

        },
        {

            HeadingName: "ALUMOX 500",
            unitText: "Amoxicillin Capsule BP",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-22.png",

        },
        {

            HeadingName: "CEFUSIM 500 mg",
            unitText: "Cefuroxime Axetil Tablets",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-23.png",

        },
        {

            HeadingName: "GLACLAVE 625",
            unitText: "Amoxicillin Potassium Clavulanate BP",
            image: process.env.PUBLIC_URL + "/assests/images/productSet/image-24.png",

        },
    ];
    return (
        <>
            <section className='product-page-card'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='mt-3 mb-md-5 mb-sm-0 mb-0'>
                                <BreadcrumComponent items={breadcrumbItems} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='  mt-md-3 mt-sm-0 mt-0   mb-md-5 mb-sm-3 mb-3 sort-box  text-md-end  text-sm-start text-start'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        {selectedSort}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleSelect('Sort by: Default')}>
                                            <Form.Check type="checkbox" label="Sort by: Default" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect('Sort by Latest')}>
                                            <Form.Check type="checkbox" label="Sort by Latest" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect('Sort by Popularity')}>
                                            <Form.Check type="checkbox" label="Sort by Popularity" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect('Sort by Price: Low to High')}>
                                            <Form.Check type="checkbox" label="Sort by Price: Low to High" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect('Sort by Price: High to Low')}>
                                            <Form.Check type="checkbox" label="Sort by Price: High to Low" />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        {productData.map((card, index) => (
                            <Col xxl={3} xl={3} lg={4} md={6} key={index} className='mb-lg-5 mb-md-5 mb-sm-3 mb-3'>
                                <Link to={card.detailsLink}>
                                    <ProductCard
                                        HeadingName={card.HeadingName}
                                        image={card.image}
                                        downloadText={"Enquire"}
                                        unitText={card.unitText}
                                    />
                                </Link>
                            </Col>
                        ))}
                        {/* <Col lg={12} className=''>
                            <div className='mx-auto mt-5'>
                                <Pagination className='justify-content-center'>
                                    <Pagination.Prev  >
                                        <div class="button1">
                                            <span className="label-up"><FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" /></span>
                                            <span className="label-up"><FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" /></span>
                                        </div>
                                    </Pagination.Prev>
                                    <Pagination.Item>
                                        <div class="button">
                                            <span className="label-up">1</span>
                                            <span className="label-up">1</span>
                                        </div>
                                    </Pagination.Item>
                                    <Pagination.Item>
                                        <div class="button">
                                            <span className="label-up">2</span>
                                            <span className="label-up">2</span>
                                        </div>
                                    </Pagination.Item>
                                    <Pagination.Item>
                                        <div class="button">
                                            <span className="label-up">3</span>
                                            <span className="label-up">3</span>
                                        </div>
                                    </Pagination.Item>

                                    <Pagination.Next  >
                                        <div class="button1">
                                            <span className="label-up"><FontAwesomeIcon icon={faArrowRight} className="arrow-icon" /></span>
                                            <span className="label-up"><FontAwesomeIcon icon={faArrowRight} className="arrow-icon" /></span>
                                        </div>
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ProductPageCard
