import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import "./index.css";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Home from "./components/home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Trading from "./components/Services/Trading/Trading";
import ContractManufacturing from "./components/Services/ContractManufacturing/ContractManufacturing";
import NamedPatient from "./components/Services/NamedPatient/NamedPatient";
import ProductPage from "./components/ProductPage/ProductPage";
import ProductEnquiry from "./components/ProductEnquiry/ProductEnquiry";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./components/PrivacyPolicy/TermsCondition";
import ContactUs from "./components/ContactUs/ContactUs";
import Welcomepage from "./components/Welcomepage/Welcomepage";
import PageNotFound from "./components/PageNotFound/PageNotFound";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isWelcomePage = location.pathname === '/';

  useEffect(() => {
    if (isWelcomePage) {
      const timer = setTimeout(() => {
        navigate('/home');
      }, 3500); // 10000 milliseconds = 10 seconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or location changes
    }
  }, [isWelcomePage, navigate]);

  return (
    <>
      <ScrollToTop />
      {!isWelcomePage && <Header />}
      <Routes>
        <Route path="/" element={<Welcomepage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/Contact-us" element={<ContactUs />} />
        {/* <Route path="/services" element={<Services />} />
        <Route path="/trading" element={<Trading />} />
        <Route path="/contract-manufacturing" element={<ContractManufacturing />} />
        <Route path="/named-patient" element={<NamedPatient />} /> */}
        <Route path="/product" element={<ProductPage />} />
        {/* <Route path="/product-enquiry" element={<ProductEnquiry />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
      </Routes>
      {!isWelcomePage && <Footer />}
    </>
  );
}

export default App;
