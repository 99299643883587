import React from 'react'
import './ProductCard.css'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const ProductCard = ({ image, HeadingName, downloadText, Icon, unitText }) => {
    return (
        <div className="product-category">
            <Card>
                <div className='product-icon text-center'>
                    <Card.Img variant="top" src={image} className='product-img' />
                </div>
                <Card.Body>
                    <Card.Title>{HeadingName} <br /> <span>{unitText}</span></Card.Title>

                    <div className='circle text-end'>
                        <div className='image-holder'>
                            <div className="tringle-img" />
                            <div className='arrow-icon'>
                                <img className="arrow-img" src={process.env.PUBLIC_URL + "/assests/images/icon/arrow-up-right.png"} alt="arrow-img" />
                            </div>
                        </div>
                    </div>
                </Card.Body>

                <Link to="/contact-us">
                    <div className='download-box'>
                        <p>{downloadText} <FontAwesomeIcon icon={Icon} /></p>
                    </div>
                </Link>
            </Card>
        </div>
    )
}

export default ProductCard
