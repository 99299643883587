import React from 'react'
import ProductBanner from './ProductBanner/ProductBanner'
import ProductPageCard from './ProductPageCard/ProductPageCard'


const ProductPage = () => {
    return (
        <>
         <div className='product-category-banner'>
         <ProductBanner headingName={"Our "} headingNameSecond={"Products"} paragraphtext={"Showing 1–12 of 20 Results"} />
         </div>
         
            <ProductPageCard />
        </>
    )
}

export default ProductPage
