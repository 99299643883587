import React, { useState } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ProductBanner from "../ProductPage/ProductBanner/ProductBanner";
import { Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import SuccesfullModal from "../CommanComponent/SuccesfullModal/SuccesfullModal";
const ContactUs = () => {
  const [phone, setPhone] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };

  const handleArrowClick = () => {
    setShowContactTextHolder(true);
    setShowForm(false);
    setShowBg(false);
  };
  const handleSendMessage = () => {
    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
      window.location.href = "/home";
    }, 3000);
  };

  return (
    <>
      <ProductBanner
        headingName={"Feel Free "}
        headingNameSecond={"to Enquire"}
      />
      <section className="contact-us product-enquiry-form  pb-5">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className="row justify-content-center mt-4">
              <div className="col-lg-5 col-md-6 ">
                <div
                  className={`contact-text-holder ${
                    showContactTextHolder ? "" : "d-none"
                  }`}
                >
                  <div className="bg-color">
                    <div className="text-holder1">
                      <h2>Get In Touch with Our Healthcare Product Experts</h2>
                      <p>
                        We value your feedback and are here to support your
                        healthcare and medical product needs.
                        <br />
                        <br />
                        At GULA Pharmaceutical, we are dedicated to enhancing
                        well-being and healthcare effectiveness. Thank you for
                        trusting us !<br />
                        <br />
                        let us assist you every step of the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  showForm
                    ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                    : "col-lg-5 col-md-6 scale-up-hor-right"
                }`}
              >
                <div className="contact-bg-color">
                  <div className="form-holder ">
                    <div
                      className={`arrow-holder ${
                        showForm ? "arrow-holder" : "d-none"
                      }`}
                      onClick={handleArrowClick}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/images/icon/arrow-left.png"
                        }
                        className="left-arrow"
                      />
                    </div>
                    <Form>
                      <Row className="form_container">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter Full Name*"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1 "
                        >
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPhone">
                          <PhoneInput
                            country={"in"}
                            enableSearch={true}
                            value={phone}
                            placeholder="Enter Contact Number*"
                            onChange={(phone) => setPhone(phone)}
                            containerClass="phone-input-container"
                            inputClass="phone-input-field"
                            buttonClass="phone-input-button"
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            className="text-holder-area"
                            placeholder="Write your message here"
                            rows={5}
                          />
                        </Form.Group>
                        <div
                          className="btn-holder "
                          onClick={handleSendMessage}
                        >
                          <button className="btn btn-submit" type="button">
                            Send Message
                          </button>
                        </div>
                        <div
                          className="address-holder map-text mt-lg-5 mt-md-3 mt-2"
                          onClick={handleMapTextClick}
                        >
                          <p>Find Us on Map</p>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="address-section">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={3} xl={3} lg={4} md={6}>
              <div className="d-lg-flex d-md-flex d-sm-unset d-unset border-line mt-lg-0 mt-md-4 mt-sm-3 mt-3">
                <div className="font-icon">
                  <FontAwesomeIcon icon={faLocationDot} className="fa-icon" />
                  <span>Address</span>
                </div>
                <div className="address-text">
                  <h5>Address</h5>
                </div>
                <div className="para-text">
                  <p>
                    Shop No -1, 2160 Sadashiv Peth, Swamipuram Appartment Pune -
                    411030, Maharashtra 27
                  </p>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={4} md={6}>
              <div className="d-lg-flex d-md-flex  d-sm-unset d-unset mt-lg-0 mt-md-4 mt-sm-3 mt-3">
                <div className="font-icon">
                  <FontAwesomeIcon icon={faEnvelope} className="fa-icon" />
                  <span>Support</span>
                </div>
                <div className="address-text">
                  <h5>Support</h5>
                </div>
                <div className="para-text">
                  <p>info@gula-pharmaceutical.com</p>
                  {/* <p>exports@gulapharma.com</p> */}
                </div>
              </div>
            </Col>
            {/* <Col lg={3} md={6}>
                            <div className="d-lg-flex d-md-flex  d-sm-unset d-unset border-line mt-lg-0 mt-md-4 mt-sm-3 mt-3">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faPhone} className="fa-icon" /><span>Phone</span>
                                </div>
                                <div className="address-text">
                                    <h5>Phone</h5>
                                </div>
                                <div className="para-text">
                                    <p>+91 1000890984</p>
                                    <p>+91 9876543210</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="d-lg-flex d-md-flex  d-sm-unset d-unset mt-lg-0 mt-md-4 mt-sm-3 mt-3">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faCalendarDay} className="fa-icon" /><span>Timings</span>
                                </div>
                                <div className="address-text">
                                    <h5>Timings</h5>
                                </div>
                                <div className="para-text">
                                    <p>Mon. - Fri. : 09:00 - 20:00</p>
                                    <p>Sat. : 11:00 - 15:00</p>
                                </div>
                            </div>
                        </Col> */}
          </Row>
        </Container>
      </section>
      <SuccesfullModal
        message="Message Sent Successfully!"
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ContactUs;
