import React from 'react'
import './CommanBanner.css'
const CommanBanner = ({ image, FirstHeading }) => {
    return (
        <>

            <div className='Comman-banner'>
                <img src={image} className="banner-img" alt="banner" />
                <div className='heading-holder '>
                    <h1 dangerouslySetInnerHTML={{ __html: FirstHeading }} />
                </div>
            </div>

        </>
    )
}

export default CommanBanner
