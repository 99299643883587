import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ProductBanner.css'
const ProductBanner = ({ headingNameSecond, headingName, paragraphtext }) => {
    return (
        <>
            <section className='product-banner'>
                <Container>
                    <Row>
                        <div className='d-flex'>
                            {/* <Col md={3}> */}
                            <div className='border-category'>
                                <h3>{headingName}</h3>
                            </div>
                            {/* </Col>
                            <Col md={6}> */}
                            <div className='textholder'>
                                <h2>{headingNameSecond}</h2>
                                <p>{paragraphtext}</p>
                            </div>
                            {/* </Col> */}
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ProductBanner
