import React from "react";
import "./Banner.css";


function Banner() {
  return (
    <section className="home-banner">
      <video className='video-class-grid' autoPlay loop muted>
        <source src={process.env.PUBLIC_URL + "/assests/images/home/banner/home-banner.mp4"} className='video' type="video/mp4" />
      </video>
    </section>
  );
}

export default Banner;
